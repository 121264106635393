<template>
    <div class="preview_area" :class="{active : modelValue}" v-if="modelValue">
        <button type="button" class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
        <div class="analytics_container">
            <div class="workout_title">
                <h3>
                    {{workout.name}}
                    <ul>
                        <li>{{ workoutAnalytics.blocks_count }} <span>Blocks</span></li>
                        <li>{{ workoutAnalytics.exercises_count }} <span>Exercises</span></li>
                        <li>{{ workoutAnalytics.durations }} <span>Duration</span></li>
                    </ul>
                </h3>
                <div class="filter" @click="globalStatsFilterDropdown = !globalStatsFilterDropdown" v-click-outside="closeStatFilterDropdown">
                    {{globalStatsSelectedFilters}} <i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="{'active' : globalStatsFilterDropdown}">
                        <ul>
                            <li v-for="(filter, f) in globalStatsFilters" :key="f" @click="globalStatsSelectedFilters = filter;">{{filter}}</li>
                        </ul>
                    </div>
                </div>
                <button type="button" class="notes_btn" @click="showNotes = true">Notes</button>
            </div>
            <div class="analytics">
                <div class="analytics_card" v-if="workoutAnalyticsLoader"><quote-loader :show-quote="false" /></div>
                <div class="analytics_card" v-else>
                    <h4>Assigned <span class="stat">{{workoutAnalytics.assign_count}}</span></h4>
                    <ul class="analytic_info column pt-2">
                        <li>
                            <h5>Completed: <span class="stat">{{ workoutAnalytics.completed }}</span></h5>
                            <div class="score_circle">
                                <div class="inner_circle">
                                    <div class="score">{{ workoutAnalytics.completion_rate }}%</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                    <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px"
                                        stroke="#f2a31d" :stroke-dashoffset="115 - (workoutAnalytics.completion_rate * 1.15)"
                                        stroke-dasharray="115" />
                                </svg>
                            </div>
                        </li>
                        <li>
                            <h5>In Progress: <span class="stat">{{ workoutAnalytics.inprogress }}</span></h5>
                            <div class="score_circle">
                                <div class="inner_circle">
                                    <div class="score">{{ workoutAnalytics.inprogress_rate }}%</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                    <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px"
                                        stroke="#1df2ce" :stroke-dashoffset="115 - (workoutAnalytics.inprogress_rate * 1.15)"
                                        stroke-dasharray="115" />
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="analytics_card" v-if="workoutAnalyticsLoader"><quote-loader :show-quote="false" /></div>
                <div class="analytics_card" v-else>
                    <h4>Ratings</h4>
                    <div class="reviews">
                        <ul>
                            <li>Average Difficulty
                                <star-rating 
                                    :increment="0.01"
                                    :max-rating="5"
                                    :rating="workoutAnalytics.average_difficulty"
                                    active-color="#2f7eed"
                                    active-border-color="#2f7eed"
                                    :border-width="1"
                                    border-color="#c5c5c5"
                                    :show-rating="false"
                                    :star-size="8"
                                    :read-only="true"
                                    :rounded-corners="true"
                                    inactive-color="#c5c5c5"
                                ></star-rating>
                            </li>
                            <li>Average Enjoyment
                                <star-rating 
                                    :increment="0.01"
                                    :max-rating="5"
                                    :rating="workoutAnalytics.average_enjoyment"
                                    active-color="#2f7eed"
                                    active-border-color="#2f7eed"
                                    :border-width="1"
                                    border-color="#c5c5c5"
                                    :show-rating="false"
                                    :star-size="8"
                                    :read-only="true"
                                    :rounded-corners="true"
                                    inactive-color="#c5c5c5"
                                ></star-rating>
                            </li>
                            <li>Average Energy
                                <star-rating 
                                    :increment="0.01"
                                    :max-rating="5"
                                    :rating="workoutAnalytics.average_energy"
                                    active-color="#2f7eed"
                                    active-border-color="#2f7eed"
                                    :border-width="1"
                                    border-color="#c5c5c5"
                                    :show-rating="false"
                                    :star-size="8"
                                    :read-only="true"
                                    :rounded-corners="true"
                                    inactive-color="#c5c5c5"
                                ></star-rating>
                            </li>
                            <li>Average Mood
                                <star-rating 
                                    :increment="0.01"
                                    :max-rating="5"
                                    :rating="workoutAnalytics.average_mood"
                                    active-color="#2f7eed"
                                    active-border-color="#2f7eed"
                                    :border-width="1"
                                    border-color="#c5c5c5"
                                    :show-rating="false"
                                    :star-size="8"
                                    :read-only="true"
                                    :rounded-corners="true"
                                    inactive-color="#c5c5c5"
                                ></star-rating>
                            </li>
                        </ul>
                    </div>
                    <ul class="analytic_info">
                        <li>
                            <label>Average Time to Complete:<a class="time">{{ workoutAnalytics.average_time_completion }}</a></label>
                        </li>
                    </ul>
                </div>
            </div>
            <button type="button" class="show_hide" @click="hasGlobalStats = !hasGlobalStats">{{ hasGlobalStats ? 'Hide' : 'Show' }} Stat</button>
            <Transition duration="550" name="nested">
                <div class="leaderboard_wpr" v-if="hasGlobalStats">
                    <div class="leaderboard">
                        <div class="board_ttl">
                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="47.5" stroke="#74AEFF" stroke-width="5"/>
                                <path d="M63.965 68.335L68.24 64.06L53.135 48.86V29.765H47.435V51.14L63.965 68.335ZM50 88C44.8067 88 39.8983 87.0025 35.275 85.0075C30.6517 83.0125 26.6142 80.2892 23.1625 76.8375C19.7108 73.3858 16.9875 69.3483 14.9925 64.725C12.9975 60.1017 12 55.1933 12 50C12 44.8067 12.9975 39.8983 14.9925 35.275C16.9875 30.6517 19.7108 26.6142 23.1625 23.1625C26.6142 19.7108 30.6517 16.9875 35.275 14.9925C39.8983 12.9975 44.8067 12 50 12C55.1933 12 60.1017 12.9975 64.725 14.9925C69.3483 16.9875 73.3858 19.7108 76.8375 23.1625C80.2892 26.6142 83.0125 30.6517 85.0075 35.275C87.0025 39.8983 88 44.8067 88 50C88 55.1933 87.0025 60.1017 85.0075 64.725C83.0125 69.3483 80.2892 73.3858 76.8375 76.8375C73.3858 80.2892 69.3483 83.0125 64.725 85.0075C60.1017 87.0025 55.1933 88 50 88Z" fill="#F2A31D"/>
                            </svg>
                            Completion Time
                        </div>
                        <ul v-if="workoutAnalyticsLoader"><quote-loader :show-quote="false" /></ul>
                        <ul v-else>
                            <li v-for="(completionTime, ct) of workoutAnalytics.completion_time_score" :key="ct">
                                <span class="board_pos">{{ ct + 1 }}</span>
                                <img :src="completionTime.photo" alt="">
                                <h5>{{ completionTime.name }}</h5>
                                <div class="streaks">{{ completionTime.time }}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="leaderboard">
                        <div class="board_ttl">
                            <svg width="88" height="89" viewBox="0 0 88 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.883679 74.284L14.3203 60.3015C19.5285 68.9191 26.4851 74.9356 27.5768 76.0388L16.7885 89L12.1998 78.0066L0.883679 74.284Z" fill="#74AEFF"/>
                                <path d="M87.545 74.284L74.1084 60.3015C68.1278 70.0606 62.0999 74.8388 61.0082 75.9421L71.6402 89L76.2289 78.0066L87.545 74.284Z" fill="#74AEFF"/>
                                <path d="M9.91659 14.2379C20.6542 12.1224 33.3325 6.09792 40.8528 1.89082C42.8568 0.769734 45.3153 0.796214 47.2991 1.95273C56.2674 7.18115 68.6132 12.5919 78.1371 14.344C81.2919 14.9244 83.972 17.4691 83.8877 20.6757C82.8652 59.56 53.3034 80.7411 45.5598 83.8083C44.6166 84.1819 43.6153 83.9381 42.7359 83.4322C12.7655 66.1918 4.49242 38.081 4.67304 19.9283C4.70193 17.0254 7.06827 14.799 9.91659 14.2379Z" fill="#2F7FED"/>
                                <path d="M42.3904 21.9507C42.9891 20.108 45.596 20.108 46.1947 21.9507L49.4034 31.8261C49.6711 32.6501 50.439 33.208 51.3055 33.208L61.6891 33.208C63.6266 33.208 64.4321 35.6873 62.8647 36.8261L54.4642 42.9294C53.7632 43.4387 53.4699 44.3414 53.7376 45.1655L56.9463 55.0409C57.545 56.8835 55.4361 58.4158 53.8686 57.277L45.4681 51.1736C44.7671 50.6643 43.8179 50.6643 43.117 51.1736L34.7164 57.277C33.149 58.4158 31.04 56.8835 31.6388 55.0409L34.8475 45.1655C35.1152 44.3414 34.8219 43.4387 34.1209 42.9294L25.7204 36.8261C24.153 35.6873 24.9585 33.208 26.896 33.208L37.2796 33.208C38.146 33.208 38.914 32.6501 39.1817 31.8261L42.3904 21.9507Z" fill="#FFBE51"/>
                            </svg>
                            Overall Rating
                        </div>
                        <ul v-if="workoutAnalyticsLoader"><quote-loader :show-quote="false" /></ul>
                        <ul v-else>
                            <li v-for="(rating, r) in workoutAnalytics.overall_rating_score" :key="r">
                                <span class="board_pos">{{r + 1}}</span>
                                <img :src="rating.photo" alt="">
                                <h5>{{ rating.name }}</h5>
                                <div class="ratings">
                                    <star-rating 
                                        :increment="0.01"
                                        :max-rating="5"
                                        :rating="rating.score"
                                        active-color="#2f7eed"
                                        active-border-color="#2f7eed"
                                        :border-width="1"
                                        border-color="#c5c5c5"
                                        :show-rating="false"
                                        :star-size="8"
                                        :read-only="true"
                                        :rounded-corners="true"
                                        inactive-color="#c5c5c5"
                                    ></star-rating>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Transition>
            <block-analytic-stats v-for="(block, b) of workoutAnalytics.blocks" :key="b" :block="block" :workout="workout" />
            <div class="section_title mt-2">Clients</div>
            <div class="tab_wpr">
                <div class="result_wpr new">
                    <div class="actions">
                        <ul>
                            <li class="optionDrops contacts-tabs" @click="contactFilterDropdown = !contactFilterDropdown" v-click-outside="closeFilterDropdown">
                                {{ params.filter }} <i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="{ 'active': contactFilterDropdown }">
                                    <ul>
                                        <li v-for="(filter, f) in contactFilters" :key="f" @click="params.filter = filter;">{{ filter }}</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="optionDrops sort_list ml-auto" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                Show {{ params.per_page }} <i class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="workout-user-per-page-filter" />
                            </li>
                        </ul>
                    </div>
                    <div class="scroll_table">
                        <table class="standard show_header">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(contact, c) in contacts.data" :key="c">
                                    <td>{{ moment(contact.created_at).format('ll') }}</td>
                                    <td>{{ contact.contact ? contact.contact.name : '-' }}</td>
                                    <td>{{ contact.contact ? contact.contact.email : '-' }}</td>
                                    <td><span class="tag">{{ contact.status }}</span></td>
                                    <td>
                                        <button type="button" @click="toggleContactActivity(contact)"><i class="fas fa-search-plus"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table_footer">
                    <ul>
                        <li>{{ contacts.from ? contacts.from : 0 }} - {{ contacts.to ? contacts.to : 0 }} of <span>{{ contacts.total ? contacts.total : 0 }}</span></li>
                    </ul>
                </div>
            </div>
            <div class="model_pagination pb-4 mt-4">
                <div class="pagination" v-show="contacts.total">
                    <pagination v-model="params.page" :range-size="0" :pages="contacts.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
        </div>
        <workout-note v-model="showNotes" :filter="globalStatsSelectedFilters" :workout="workout" />
        <workout-activity v-model="viewActivity" :filter="globalStatsSelectedFilters" :workout="workout" :assign-id="selectedContact.id" :contact-id="selectedContact.contact_id" />
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from  'vuex'

const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))
const BlockAnalyticStats = defineAsyncComponent(() => import('@/pages/workout/components/BlockAnalyticStats'))
const WorkoutNote = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutNote'))
const WorkoutActivity = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutActivity'))

import moment from 'moment-timezone'
import StarRating from 'vue-star-rating'

export default {
    name: 'Workout Analytics',

    data() {
        return {
            params: {
                id: 0,
                page: 1,
                per_page: 5,
                search: '',
                filter: 'All',
                status: 'all',
                analytic: true,
                assetType: 'workout',
            },
            globalStatsSelectedFilters: 'All Time',
            globalStatsFilters: ['Last 7 Days', 'Last 14 Days', 'Last 30 Days', 'All Time'],
            globalStatsFilterDropdown: false,
            hasGlobalStats: false,
            contactFilterDropdown: false,
            contactFilters: ['All', 'Active', 'Completed', 'Restricted'],
            viewActivity: false,
            showNotes: false,
            tab: 'workout',
            moment,
            selectedContact: {},
        }
    },

    props: {
        modelValue: Boolean,
        workout: Object,
    },

    emit: ['update:modelValue'],

    watch: {
        modelValue (val) {
            const vm = this;

            if (val) {
                vm.params.id        = vm.workout.id;
                vm.params.page      = 1;
                vm.tab              = 'workout';
                vm.hasGlobalStats   = false;
                vm.showNotes        = false;

                vm.getWorkoutAnalytics({ workout: vm.workout.id, filter: vm.globalStatsSelectedFilters.toLowerCase() });
                vm.getAssignContacts(vm.params);

                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        },


        globalStatsSelectedFilters (filter) {
            const vm = this;

            vm.getWorkoutAnalytics({ workout: vm.workout.id, filter: filter.toLowerCase() });
        },

        'params.per_page' () {
            const vm = this;

            vm.params.page = 1;
            vm.getAssignContacts(vm.params);
        },

        'params.filter' (filter) {
            const vm = this;

            vm.params.status = filter.toLowerCase();
            vm.getAssignContacts(vm.params);
        },

        'params.search' () {
                const vm = this;

            setTimeout(() => {
                vm.isTyping = false;
            }, 1500);
        },

        isTyping (val) {
            const vm = this;

            if (!val) {
                if (vm.params.search != null) {
                    if (vm.params.search.length >= 2 || vm.params.search.length === 0) {
                        vm.params.page = 1;
                        vm.getAssignContacts(vm.params);
                    }
                }
            }
        },
    },

    computed: mapState({
        workoutAnalytics: state => state.workoutModule.workoutAnalytics,
        workoutAnalyticsLoader: state => state.workoutModule.workoutAnalyticsLoader,
        contacts: state => state.workoutModule.activeContacts,
    }),

    components: {
        StarRating,
        WorkoutNote,
        BlockAnalyticStats,
        WorkoutActivity,
    },

    methods: {
        ...mapActions({
            getWorkoutAnalytics: 'workoutModule/getWorkoutAnalytics',
            getAssignContacts: 'workoutModule/getAssignContacts',
        }),

        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },

        togglePerPageFilter () {
            const vm = this;
            vm.actionList = false;
            vm.bulkActionDropdown = false;

            const filter = vm.$refs['workout-user-per-page-filter'];

            if (filter) {
                filter.dropdown = !filter.dropdown;
            }
        },

        closePerPageDropdown () {
            const vm = this;

            const filter = vm.$refs['workout-user-per-page-filter'];

            if (filter) {
                filter.dropdown = false;
            }
        },

        closeStatFilterDropdown () {
            const vm = this;

            vm.globalStatsFilterDropdown = false;
        },

        closeFilterDropdown () {
            const vm = this;

            vm.contactFilterDropdown = false;
        },

        handlePagination () {
            const vm = this;

            vm.getAssignContacts(vm.params);
        },

        toggleContactActivity (contact) {
            const vm = this;

            vm.selectedContact  = contact;
            vm.viewActivity     = true;
        },
    }
}
</script>

<style scoped>
.preview_area {
    background: #f5f5f5;
}

.analytics_container {
    max-width: 1170px;
    padding: 0 20px;
    margin: 0 auto;
}

.preview_area .close_btn {
    right: 15px;
    top: 15px;
    left: auto;
    position: fixed;
    background: #eaeaea;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.workout_title {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    margin-top: 30px;
}

.workout_title h3 {
    font-size: 22px;
    line-height: 30px;
    color: #121525;
    font-weight: 500;
    text-align: left;
}

.workout_title h3 ul {
    display: flex;
    align-items: center;
    padding: 10px 0 4px;
    gap: 10px;
}

.workout_title h3 ul li {
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    padding-right: 10px;
}

.workout_title h3 ul li:not(:last-child) {
    border-right: 1px solid #d9d9d9;
}

.workout_title h3 ul li span {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
}

.workout_title .notes_btn {
    font-size: 13px;
    line-height: 25px;
    font-weight: 400;
    color: #2f7eed;
    cursor: pointer;
    margin-left: 10px;
}

.section_title {
    font-size: 20px;
    line-height: 26px;
    color: #121525;
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 12px;
}

.section_title .Circuit,
.section_title .Progression {
    height: 20px;
    padding: 0 10px;
    border-radius: 12px;
    background: #eef5ff;
    border: 1px solid #c5ddff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #2f7eed;
}

/* .section_title .Progression{
    background: #fff8ec;
    border-color: #ffd283;
    color: #f2a31d;
} */
.section_title .filter,
.workout_title .filter {
    padding: 3px 0;
    margin-left: auto;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    position: relative;
    z-index: 3;
    cursor: pointer;
}

.section_title .filter .dropdown_wpr,
.workout_title .filter .dropdown_wpr {
    min-width: 160px;
    left: auto;
    right: 1px;
}
.workout_title .filter .dropdown_wpr::before{
    display: none;
}

.section_title .filter .dropdown_wpr ul li,
.workout_title .filter .dropdown_wpr ul li {
    border: 0;
    text-align: left;
}

.section_title .filter i,
.workout_title .filter i {
    margin-left: 10px;
    color: #7a7a7a;
    pointer-events: none;
}

.analytics {
    display: flex;
    margin: 20px 0 40px 0;
    gap: 30px;
}

.analytics .analytics_card:first-of-type {
    /* flex: 0 0 355px; */
    flex: 0 0 31.5%;
}

.analytics_card {
    /* border: 1px solid #eaeaea; */
    background: #fff;
    border-radius: 8px;
    width: 100%;
    padding: 20px 20px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
}

.analytics_card h4 {
    font-size: 17px;
    line-height: 25px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.analytics_card h4 .stat {
    font-size: 22px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
}

.analytics_card .analytic_info {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.analytics_card .analytic_info.column {
    flex-direction: column;
}

.analytics_card .analytic_info li {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.analytics_card h5 {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.analytics_card h5 .stat {
    font-size: 20px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
}

.analytics_card label {
    font-size: 13px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.analytics_card label .time {
    font-size: 18px;
    line-height: 23px;
    color: #2f7eed;
    font-weight: 400;
    display: flex;
    align-items: flex-end;
}

.analytics_card label .time span {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 400;
    padding: 0 8px 2px 3px;
}

.analytics_card .reviews {
    margin: 5px 0 20px 0;
}

.analytics_card .reviews ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}

.analytics_card .reviews ul li {
    flex: 1 1 auto;
    background: #f5f5f5;
    border-radius: 3px;
    padding: 10px;
    margin: 5px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.analytics_card .ratings {
    display: flex;
    gap: 4px;
}

.analytics_card .ratings span {
    color: #2f7eed;
    font-size: 10px;
}

.analytics_card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.analytics_card .score_circle {
    width: 40px;
    height: 40px;
    padding: 3px;
}

.analytics_card .score_circle .inner_circle {
    background: #fff;
}

.score_circle .inner_circle .score {
    font-size: 11px;
}

.analytics_modal .tab_row li.active {
    color: #2f7eed;
}

.analytics_modal .tab_row {
    padding: 20px 0 0 0;
    display: flex;
    gap: 30px;
}

.analytics_modal .tab_row li {
    padding-bottom: 12px;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #121525;
    overflow: hidden;
}

.analytics_modal .tab_row li:after {
    position: absolute;
    content: '';
    left: -100%;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #2F80ED;
    transition: all 0.3s ease-in-out;
}

.analytics_modal .tab_row li.active {
    color: #2f7eed;
}

.analytics_modal .tab_row li.active:after {
    left: 0;
}

.leaderboard_wpr {
    margin: 15px 0 40px 0;
}

.show_hide {
    font-size: 11px;
    line-height: 14px;
    background: #fff;
    border: 1px solid #f5f5f5;
    padding: 7px 15px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.leaderboard {
    border: 0;
}

.leaderboard .board_ttl {
    justify-content: flex-start;
    gap: 10px;
    line-height: 18px;
    border: 0;
    padding: 12px 20px 12px 15px;
}

.leaderboard .board_ttl i {
    color: #5a5a5a;
    cursor: pointer;
    margin-left: auto;
}

.leaderboard .board_ttl svg {
    height: 18px;
    width: auto;

}

.leaderboard ul {
    background: #fff;
    height: 200px;
}

.leaderboard ul li h5 {
    margin-right: auto;
}

.leaderboard ul li .streaks {
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
}

.leaderboard ul li .ratings {
    display: flex;
    gap: 1px;
}

.leaderboard ul li .ratings span {
    color: #2f7eed;
    font-size: 8px;
}

.leaderboard_slider {
    padding: 15px 20px 40px 20px;
    margin: 0 -20px
}

.leaderboard_slider :deep(.swiper-slide) {
    width: auto
}

.leaderboard_slider :deep(.leaderboard) {
    width: 355px;
}

.leaderboard_slider :deep(.leaderboard ul) {
    padding-top: 0;
    height: 210px;
}

.leaderboard_slider :deep(.leaderboard li.header) {
    display: flex;
    justify-content: flex-end;
    background: #fbfbfb;
    position: sticky;
    top: 0;
    z-index: 2;
}

.leaderboard_slider :deep(.leaderboard li.header span) {
    width: 40px;
    font-size: 10px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
    padding: 0 5px;
    display: flex;
    justify-content: center;
}

.leaderboard_slider :deep(.leaderboard li .marks) {
    display: flex;
    justify-content: flex-end;
}

.leaderboard_slider :deep(.leaderboard li .marks span) {
    width: 40px;
    padding: 0 5px;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    justify-content: center;
}

.leaderboard_slider :deep(.swiper-button-next.swiper-button-disabled),
.leaderboard_slider :deep(.swiper-button-prev.swiper-button-disabled) {
    opacity: 0;
}

/* .leaderboard_slider :deep(.swiper-button-next),
.leaderboard_slider :deep(.swiper-button-prev){
    width: 25px;
    height: 25px;
    background: #f2a31d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.leaderboard_slider :deep(.swiper-button-next) {
    right: 0;
}

.leaderboard_slider :deep(.swiper-button-prev) {
    left: 0;
}

.leaderboard_slider :deep(.swiper-button-next:after),
.leaderboard_slider :deep(.swiper-button-prev:after) {
    font-size: 18px;
    font-weight: 600;
}

.result_wpr table td:first-child,
.result_wpr table th:first-child {
    width: auto;
    text-align: left;
    padding-left: 15px;
}

.result_wpr table td .tag {
    padding: 3px 10px;
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
    border-radius: 10px;
    background: #efefef;
    color: #121525;
}

.result_wpr table td button {
    color: #2f7eed;
}

.modal .modal_header .close_btn.left_out {
    position: absolute;
}

.modal.workout_modal .modal_body {
    padding-top: 15px;
    padding-bottom: 0;
    background: #fff;
}

.modal.workout_notes .modal_body {
    padding-top: 15px;
    background: #fff;
}

.modal.workout_modal .btn_list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -5px;
}

.modal.workout_modal .btn_list li {
    font-size: 11px;
    line-height: 15px;
    color: #121525;
    padding: 5px 10px;
    white-space: nowrap;
    cursor: pointer;
}

.modal.workout_modal .modal_body .setting_wpr {
    height: 100%;
}

.workout_modal .slot_content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.workout_modal .slot_content .history {
    background: #fbfbfb;
    border-top: 1px solid #f9f9f9;
    margin: 0 -45px;
    padding: 0 45px;
    flex: 1 1 auto;
}

.workout_modal .slot_content .nav_bar {
    padding: 15px 45px;
    margin: 0 -45px;
    background: #fff;
    border-bottom: 1px solid #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 2;
}

.workout_modal .slot_content .nav_bar .back_btn {
    font-size: 12px;
    font-weight: 15px;
    font-weight: 500;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
}

.workout_modal .slot_content .toggle_content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.workout_modal .slot_content .toggle_content.show {
    max-height: 100000px;
}

.workout_modal .slot_content .empty_text {
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    color: #999;
    text-align: center;
    padding: 25px 0;
}

.workout_modal h3.sub_heading2 {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    padding: 30px 0 0;
    margin: 0;
}

.workout_modal h3.sub_heading2 i {
    color: #999;
    margin-left: auto;
    transform: rotate(0);
    transition: all 0.3s ease-in-out;
    display: none;
}

.workout_modal h3.sub_heading2.rotate i {
    transform: rotate(-180deg);
}

.workout_modal .workout_card {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding-bottom: 10px;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid #e9e9e9;
}

.workout_modal .workout_card:last-of-type {
    border: 0;
    padding-bottom: 20px;
}

.workout_modal .workout_card li {
    display: flex;
    flex-direction: column;
}

.workout_modal .workout_card li a {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: var(--var-calendar-event-cta-color, #2f7eed);
    margin: 0 0 0 auto;
    cursor: pointer;
}

.workout_modal .workout_card li .category_title {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    margin: 0 auto 7px 0;
}

.workout_modal .workout_card li .card_wrapper {
    border-radius: 6px;
    display: flex;
    gap: 12px;
    cursor: pointer;
    padding: 20px 0 0 0;
}

.workout_modal .workout_card li .card_wrapper svg {
    flex: 0 0 18px;
    height: 18px;
}

.workout_modal .workout_card li .video_area {
    width: 25%;
    border-right: 1px solid #f5f5f5;
}

.workout_modal .workout_card li .video_area img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.workout_modal .workout_card li .info_wpr {
    border-bottom: 1px solid #e9e9e9;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-bottom: 20px;
    gap: 0;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_card li:last-child .info_wpr {
    border-bottom: 0;
}

.workout_modal .workout_card li .info_wpr h4 {
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 20px;
    gap: 7px;
    justify-content: space-between;
    position: relative;
}

.workout_modal .workout_card li .info_wpr span.tag {
    background: #ecf4ff;
    padding: 1px 6px;
    border-radius: 8px;
    font-size: 9px;
    line-height: 15px;
    font-weight: 500;
    color: #2f7eed;
}

.workout_modal .workout_card li .info_wpr h4 i {
    position: absolute;
    top: 5px;
    right: 0;
    font-size: 10px;
    color: #999;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_list li .info_wpr h4 i {
    margin-left: 15px;
    pointer-events: none;
}

.workout_modal .workout_card li .info_wpr h6 {
    font-size: 11px;
    line-height: 15px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 -5px 5px -5px; */
    gap: 5px;
}

.workout_modal .workout_card li .info_wpr h6 span {
    padding: 0 5px 0 0;
}

.workout_modal .workout_card li .info_wpr h6 span:not(:last-child) {
    border-right: 1px solid #d9d9d9;
}

.workout_modal .workout_card li .info_wpr .blocks {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 10px;
    flex: 0 0 100%;
}

.workout_modal .workout_card li .info_wpr .blocks .block_title {
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 400;
}

.workout_modal .workout_card li .info_wpr .blocks .block_title .block_type {
    font-size: 9px;
    line-height: 12px;
    color: var(--var-calendar-event-tag-text-color, #2f7eed);
    font-weight: 500;
    margin-left: 10px;
    background: var(--var-calendar-event-tag-color, #ecf4ff);
    padding: 3px 8px;
    border-radius: 10px;
}

.workout_modal .workout_card li .info_wpr .blocks .block_title h6 {
    font-weight: 400;
    margin-top: 10px;
}

.workout_modal .workout_card li .info_wpr .block_item {
    border: 1px solid #f5f5f5;
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.workout_modal .workout_card li .info_wpr .block_item h5 {
    font-size: 11px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
}

.workout_modal .workout_card li .info_wpr .block_item h6 {
    font-size: 9px;
    line-height: 12px;
    color: #5a5a5a;
    font-weight: 400;
}

.workout_modal .workout_card li .info_wpr .block_item h6 .v_tag {
    width: 14px;
    height: 14px;
    background: #999;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
    color: #fff;
    margin-right: 5px;
    border-radius: 50%;
    padding: 0;
}

.table_wpr {
    width: 406px;
    overflow-x: auto;
}

table.task_history {
    min-width: 350px;
    width: 100%;
    border-spacing: 1px;
    background: #eaeaea;
}

.task_history th,
.task_history td {
    padding: 10px 0;
    border-radius: 3px;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
}

.task_history thead tr {
    border-bottom: 1px solid #e9e9e9;
}

.task_history td.exercise_title {
    padding: 7px 15px;
    background: #fbfbfb;
    font-size: 11px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
}

.task_history td.exercise_title .title {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.task_history td.exercise_title .title i {
    color: #5a5a5a;
}

.task_history td.exercise_title img {
    display: none;
}

.task_history td.exercise_title.show img {
    display: block;
}

.task_history td {
    width: 10%;
    background: #fff;
    color: #777;
}

.workout_modal .workout_card li .info_wpr .details {
    display: flex;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    gap: 15px;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_card li .info_wpr .details .feedback_btn {
    background: #f5f5f5;
    padding: 4px 10px;
    border-radius: 11px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    margin: 10px auto 0 auto;
}

.workout_modal .workout_card li .info_wpr .details .workout_reviews {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 0;
    padding-bottom: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_card li .info_wpr .details .workout_reviews.show {
    max-height: 500px;
    padding-bottom: 15px;
    margin-top: 10px;
}

.workout_reviews .finish_timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
}

.workout_reviews h3 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #121525;
}

.workout_reviews .finish_timer .total_timer {
    display: flex;
    gap: 7px;
}

.workout_reviews .finish_timer .total_timer label {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
}

.workout_reviews .finish_timer .total_timer label span {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
}

.workout_reviews .reviews {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.workout_reviews .reviews ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
}

.workout_reviews .reviews ul li {
    flex: 1 1 auto;
    background: #f5f5f5;
    border-radius: 3px;
    padding: 10px;
    font-size: 9px;
    line-height: 12px;
    color: #121525;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.workout_reviews .reviews ul li span {
    font-size: 15px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
}

.workout_reviews .notes {
    background: #f5f5f5;
    padding: 10px 15px 15px 15px;
    border-radius: 5px;
}

.workout_reviews .notes h4 {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    margin-bottom: 5px;
}

.workout_reviews .notes p {
    font-size: 11px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
}

.workout_modal .workout_card li .info_wpr .details .footer_action {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.cloud-icon {
    background: #f0f6ff;
    padding: 1px 6px;
    border-radius: 8px;
    font-size: 9px;
    line-height: 15px;
    font-weight: 500;
    color: #2f7eed;
}

.cloud-icon.green {
    background: #effff2;
    color: #23993e;
}

.workout_modal .workout_card li .info_wpr .details p.already-submitted {
    font-size: 11px;
    line-height: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: auto;
}

.workout_modal .workout_card li .info_wpr .details .redirect_btn {
    font-size: 11px;
    line-height: 14px;
    padding: 6px 12px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    margin: 0 0 0 auto;
    text-decoration: none;
}

.workout_modal .workout_card li .info_wpr .details .details_info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
}

.workout_modal .workout_card li.show .info_wpr {
    gap: 15px;
}

.workout_modal .workout_card li.show .info_wpr h4 i {
    transform: rotate(-180deg);
}

.workout_modal .workout_card li.show .info_wpr .details {
    max-height: 10000px;
}

.workout_modal .cell {
    width: 405px;
    padding: 60px 15px 80px;
    border-radius: 40px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    margin: 30px auto;
}

.workout_modal .cell:after,
.workout_modal .cell:before {
    position: absolute;
    content: "";
    width: 50px;
    background: #f2f2f2;
    left: 50%;
    transform: translateX(-50%);
}

.workout_modal .cell:before {
    height: 7px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    top: 25px;
}

.workout_modal .cell:after {
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    bottom: 15px;
}

.workout_modal .cell .content_area {
    height: 610px;
    padding: 0;
    border: 1px solid #e7e7e7;
    background: #fafafb;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.workout_modal .cell .content_area::-webkit-scrollbar {
    display: none;
}

.notes_list .search_area {
    background: #f9f9f9;
    border-radius: 20px;
    padding-right: 25px;
    position: relative;
    margin-bottom: 20px;
}

.notes_list .search_area input {
    height: 32px;
    width: 100%;
    background: transparent;
    padding: 0 15px;
    font-size: 11px;
    font-weight: 400;
    color: #5a5a5a;
}

.notes_list .search_area .search_btn {
    position: absolute;
    right: 3px;
    top: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 11px;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.notes_list ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 10px;
}

.notes_list ul li {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.notes_list ul li label {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
}

.notes_list ul li .note {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 15px;
    line-height: 0;
}

.notes_list ul li .note .user {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 15px;
}

.notes_list ul li .note .user img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.notes_list ul li .note .user_info {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.notes_list ul li .note .user_info span {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
}

.notes_list ul li .note q {
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
    font-style: italic;
}

.notes_list ul li .note q:before,
.notes_list ul li .note q:after {
    font-size: 15px;
}

.notes_list ul li .note textarea {
    width: 100%;
    max-height: 0;
    height: 100px;
    overflow: hidden;
    padding: 0 15px;
    border-radius: 5px;
    background: #fff;
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
    resize: none;
    margin: 0;
    transition: all 0.3s ease-in-out;
}

.notes_list ul li .action_area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.notes_list ul li .timeline {
    font-size: 11px;
    line-height: 14px;
    color: #999;
    font-weight: 400;
    font-style: italic;
    margin-right: auto;
}

.notes_list ul li .cancel_btn {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
    cursor: pointer;
    padding-right: 10px;
    border-right: 1px solid #d9d9d9;
    display: none;
}

.notes_list ul li .reply_btn {
    font-size: 11px;
    line-height: 14px;
    color: #2f7eed;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.notes_list ul li .reply_btn.send {
    display: none;
}

.notes_list ul li.active .note textarea {
    max-height: 100px;
    padding: 10px 15px;
    margin: 10px 0 0 0;
}

.notes_list ul li.active .reply_btn:not(.send) {
    display: none;
}

.notes_list ul li.active .reply_btn.send,
.notes_list ul li.active .cancel_btn {
    display: block;
}
@media(max-width: 767px) {
    .modal.analytics_modal .result_wpr .actions>ul>li.optionDrops {
        flex: 0 1 auto !important;
    }

    .analytics .reviews li {
        width: 33.333%;
    }

    .workout_modal .cell {
        transform: scale(0.7) translateX(-20%) translateY(-20%);
    }
}
@media(max-width: 650px) {
    .analytics{
        flex-wrap: wrap;
    }
    
    .analytics .analytics_card, .analytics .analytics_card:first-of-type{
        flex: 0 0 100%;
    }
    .leaderboard {
        flex: 0 0 100%;
    }
}

@media(max-width: 499px) {
    .modal.analytics_modal .modal_container .modal_header {
        padding: 20px 20px 0 20px;
    }

    .modal.analytics_modal .modal_container .modal_body {
        padding: 0 20px;
    }

    .analytics li .analytics_card {
        padding: 15px 5px;
    }

    .analytics_card .score_circle {
        transform: none;
    }

    .analytics li .analytics_card h4 {
        margin-top: 7px !important;
    }
    .workout_title{
        flex-wrap: wrap;
    }
    .workout_title h3{
        width: 100%;
    }
    .workout_title .filter {
        margin: 0 auto 0 0;
    }
    .workout_title .filter .dropdown_wpr{
        right: auto;
        left: 1px;
    }
    .analytics{
        margin-top: 10px;
    }
}

@media(max-width: 450px) {
    .result_wpr.new .actions>ul li.search_area {
        flex: 1 0 200px;
        width: auto;
    }
}
</style>